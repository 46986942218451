import { CountryCode } from "../../settings/countries";

export const validateEmail = (email: string): boolean => {
  // This is an email validator to achieve with the same validation as the one used in the backend.
  // https://github.com/barkibu/barkibu_insurance/blob/master/app/validators/email_validator.rb
  // The following comment is copied from there:

  // from https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s01.html
  // The regexp proposed in the book is very permissive and includes all the characters permitted by RFC 5322, which governs the email message format.
  // Not all email software can handle all these characters, so we're using a more restrictive regexp
  // PERMISSIVE_USERNAME_PART_REGEXP = /[\w!#$%&'*+\/=?`{|}~^-]+/i # permitted by RFC 5322
  const usernamePart = /^[\w+-]+(?:\.[\w+-]+)*@/i;
  const domainPart = /[A-Z0-9.-]+\./i;
  const topLevelDomainPart = /[A-Z]{2,6}$/i;

  return usernamePart.test(email) && domainPart.test(email) && topLevelDomainPart.test(email);
};

export const validateZipCode = (zipCode: string, country: CountryCode): boolean => {
  const zipCodeRegexByCountry: { DE: RegExp; ES: RegExp; FR: RegExp; IT: RegExp; PT: RegExp } = {
    DE: /^\d{5}$/,
    ES: /^\d{5}$/,
    FR: /^\d{5}$/,
    IT: /^\d{5}$/,
    PT: /^\d{4}-\d{3}$/,
  };

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const regex = zipCodeRegexByCountry[country] || /^\d+$/;

  return regex.test(zipCode);
};
